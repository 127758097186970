@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 222.2 84% 4.9%;

    --card: 0 0% 100%;
    --card-foreground: 222.2 84% 4.9%;

    --popover: 0 0% 100%;
    --popover-foreground: 222.2 84% 4.9%;

    --primary: 222.2 47.4% 11.2%;
    --primary-foreground: 210 40% 98%;

    --primary_gradient: 222.2 47.4% 11.2%;

    --secondary: 210 40% 96.1%;
    --secondary-foreground: 222.2 47.4% 11.2%;

    --muted: 210 40% 96.1%;
    --muted-foreground: 215.4 16.3% 46.9%;

    --accent: 210 40% 96.1%;
    --accent-foreground: 222.2 47.4% 11.2%;

    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 210 40% 98%;

    --border: 214.3 31.8% 91.4%;
    --input: 214.3 31.8% 91.4%;
    --ring: 222.2 84% 4.9%;

    --radius: 0.5rem;
  }

  .dark {
    --background: 222.2 84% 4.9%;
    --foreground: 210 40% 98%;

    --card: 222.2 84% 4.9%;
    --card-foreground: 210 40% 98%;

    --popover: 222.2 84% 4.9%;
    --popover-foreground: 210 40% 98%;

    --primary: 210 40% 98%;
    --primary-foreground: 222.2 47.4% 11.2%;

    --secondary: 217.2 32.6% 17.5%;
    --secondary-foreground: 210 40% 98%;

    --muted: 217.2 32.6% 17.5%;
    --muted-foreground: 215 20.2% 65.1%;

    --accent: 217.2 32.6% 17.5%;
    --accent-foreground: 210 40% 98%;

    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 210 40% 98%;

    --border: 217.2 32.6% 17.5%;
    --input: 217.2 32.6% 17.5%;
    --ring: 212.7 26.8% 83.9%;
  }
}

/* Add this to your CSS file */
.input-hide-arrows::-webkit-inner-spin-button,
.input-hide-arrows::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.input-hide-arrows {
  -moz-appearance: textfield;
}

.border-gradient-bottom {
  position: relative;
}

.border-gradient-bottom:after {
  content: "";
  position: absolute;
  left: 50%;
  right: 0;
  bottom: -2px;
  height: 2px;
  border-radius: 2px;
  background-image: linear-gradient(
    116deg,
    #a554e5 1.56%,
    #ff00d6 66.67%,
    #a554e5 100%
  );
  width: 0;
  transition: all 300ms ease;
}

.border-gradient-bottom:hover:after {
  width: 100%;
  left: 0;
}

.text-gradient {
  background: linear-gradient(74.96deg, #ff00d6, #9b5ee6);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.stroke-gradient {
  background: linear-gradient(74.96deg, #ff00d6, #9b5ee6);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

body.modal-open {
  overflow: hidden;
  padding-right: calc(var(--scrollbarWidth));
}

@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply bg-background text-foreground;
  }

  .container {
    @apply max-w-[1535px] xl:max-w-[1279px] lg:max-w-[1023px] md:max-w-[767px] sm:max-w-[639px];
  }
}

.loader-38 {
  width: 96px;
  height: 96px;
  display: inline-block;
  position: relative;
  color: linear-gradient(74.96deg, #ff00d6, #9b5ee6);
  -webkit-animation: rotation 1s linear infinite;
  animation: rotation 1s linear infinite;
  transition: 0.3s linear;
}
.loader-38:after,
.loader-38:before {
  content: "";
  position: absolute;
  width: 48px;
  height: 48px;
  top: 50%;
  left: 50%;
  transform: scale(0.5) translate(0, 0);
  background: linear-gradient(74.96deg, #ff00d6, #9b5ee6);
  border-radius: 50%;
  -webkit-animation: animloader38 1s infinite ease-in-out;
  animation: animloader38 1s infinite ease-in-out;
}
.loader-38:before {
  background: linear-gradient(
    116deg,
    #fff0ff 0%,
    #fed6ff 44.27%,
    #fdb3ff 67.71%,
    #fdefff 100%
  );
  transform: scale(0.5) translate(-96px, -96px);
}

/* keyFrames */
@-webkit-keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes animloader38 {
  50% {
    transform: scale(1) translate(-50%, -50%);
  }
}
@keyframes animloader38 {
  50% {
    transform: scale(1) translate(-50%, -50%);
  }
}

.markdown {
  @apply leading-6 whitespace-pre-wrap;
}

.markdown a {
  @apply underline text-fuchsia-600 decoration-fuchsia-600;
}

.markdown ul {
  @apply list-disc pl-4 leading-4 mt-0 mb-0;
}
.markdown ul li {
  @apply list-disc pl-4 leading-4 mt-0 mb-0;
}

.markdown ol {
  @apply list-decimal pl-4;
}

.markdown ol li {
  @apply list-decimal pl-4 leading-4 mt-0 mb-0;
}


